import React, { Suspense, useEffect, useContext } from "react"
import { BlocksContext } from "../context/blocksContext"
import Header from "./header"
import { Menu } from "./menu"

import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Contacts from "./contacts";
import Footer from "./footer";
import Plans from "./plans";
window.jQuery = window.$ = $;
require('bootstrap');
require('bootstrap-datepicker');


export const Loader = () => {

    const EasierToBuy = React.lazy(() => import("./easierToByu"))
    const About = React.lazy(() => import("./about"))
    const Whitebox = React.lazy(() => import("./whitebox"))
    const Action = React.lazy(() => import("./action"))
    const Location = React.lazy(() => import("./location"))
    const Outdoor = React.lazy(() => import("./outdoor"))
    const Parking = React.lazy(() => import("./parking"))
    const HowToBuy = React.lazy(() => import("./howToBuy"))
    const Commercy = React.lazy(() => import("./commercy"))
    const HodStr = React.lazy(() => import("./hodStr"))
    const Developer = React.lazy(() => import("./developer"))

    const blocksImports = [
        <Menu />,
        <Header />,
        <EasierToBuy />,
        <Plans />,
        <About />,
        <Whitebox />,
        <Action />,
        <Location />,
        <Outdoor />,
        <Parking />,
        <HowToBuy />,
        <Commercy />,
        <HodStr />,
        <Developer />,
        <Contacts />,
        <Footer />,
    ]
    
    const loaded = useContext(BlocksContext)

    const LoadBlock = (block) => {
        return <Suspense fallback={<div>Загрузка...</div>}>{block}</Suspense>
    }

    const generateHtml = () => {
        let toDraw = []
        for (let i = 0; i < loaded.blocks; i++) {
            toDraw.push(LoadBlock(blocksImports[i]))
        }
        return (
            <div className="container_main">
                <div className="blocks" data={loaded.menuClick ? "true" : ""}>
                    {toDraw.map((block) =>
                        block
                    )}
                </div>
            </div>

        )
    }
    const handleScroll = (event) => {
        if (loaded.blocks < blocksImports.length) {
            loaded.setBlocks(blocksImports.length)
        } else {
            window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(() => {
        if (loaded.blocks < blocksImports.length) {
            window.addEventListener('scroll', handleScroll, true);
        }
    })
    return generateHtml()

}