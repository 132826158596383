import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSendForm } from "../hooks/send-from.hook";
import { Form } from "./form";

export const ModalC = ({ title, title2, position, hidden, formClassName, flat, fields, celtype, btnTitle, success, personal, template, callback, close, puForm }) => {
  const sendForm = useSendForm()
  const [successShow, setSuccessShow] = useState(false)
  const successCallback = () => {
    setSuccessShow(true)
  }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: position,
      });
    }, 0)
  }, [])

  if (success || successShow) {
    return <React.Fragment>
      <div className="mfp-bg mfp-move-horizontal mfp-ready"></div>
      <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-move-horizontal mfp-ready" tabindex="-1" style={{ overflow: "hidden auto" }}>
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div id="success-popup" className="success-popup popup">
              <svg className="checkmark-wrapper" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 9.4">
                <path className="checkmark active" d="M1,4.3l4.6,4.1L14,1" stroke="#11366a"></path>
              </svg>
              <div className="success-popup__caption">
                Благодарим за заявку!
              </div>
              <div className="success-popup__description">
                Мы свяжемся с Вами в течение 15-20 минут в рабочее время
              </div>
              <button title="Close (Esc)" type="button" className="mfp-close" onClick={(e) => { e.preventDefault(); close() }}>×</button></div>
          </div>
          <div className="mfp-preloader">Loading...</div>
        </div>
      </div>
    </React.Fragment>
  }

  if (flat) {
    const getTitle = (rooms) => {
      switch (rooms) {
        case 0:
          return "Студия"
        case 1:
          return "1-комн"
        case 2:
          return "2-комн"
        case 3:
          return "3-комн"
      }
    }

    const getSubTitle = (rooms) => {
      switch (rooms) {
        case 0:
          return "cтудии"
        case 1:
          return "1-комн"
        case 2:
          return "2-комн"
        case 3:
          return "3-комн"
      }
    }
    return (


      <div style={{ display: "block" }} className="pu_rgba">
        <div className="pu_table">
          <div className="pu_cell">
            <div className="pu_inner pu_flat">
              <h2 className="caption__h2 caption__h2--brown"><span className="aos-init aos-animate">{getTitle(flat.rooms)}</span></h2>
              <div className="closeform">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { close() }}>
                  <path d="M8.93998 8.30937L13.14 4.11604C13.2655 3.9905 13.336 3.82024 13.336 3.64271C13.336 3.46517 13.2655 3.29491 13.14 3.16937C13.0144 3.04384 12.8442 2.97331 12.6666 2.97331C12.4891 2.97331 12.3188 3.04384 12.1933 3.16937L7.99998 7.36937L3.80665 3.16937C3.68111 3.04384 3.51085 2.97331 3.33331 2.97331C3.15578 2.97331 2.98552 3.04384 2.85998 3.16937C2.73444 3.29491 2.66392 3.46517 2.66392 3.64271C2.66392 3.82024 2.73444 3.9905 2.85998 4.11604L7.05998 8.30937L2.85998 12.5027C2.79749 12.5647 2.7479 12.6384 2.71405 12.7197C2.68021 12.8009 2.66278 12.888 2.66278 12.976C2.66278 13.064 2.68021 13.1512 2.71405 13.2324C2.7479 13.3137 2.79749 13.3874 2.85998 13.4494C2.92195 13.5119 2.99569 13.5615 3.07693 13.5953C3.15817 13.6291 3.24531 13.6466 3.33331 13.6466C3.42132 13.6466 3.50846 13.6291 3.5897 13.5953C3.67094 13.5615 3.74467 13.5119 3.80665 13.4494L7.99998 9.24937L12.1933 13.4494C12.2553 13.5119 12.329 13.5615 12.4103 13.5953C12.4915 13.6291 12.5786 13.6466 12.6666 13.6466C12.7547 13.6466 12.8418 13.6291 12.923 13.5953C13.0043 13.5615 13.078 13.5119 13.14 13.4494C13.2025 13.3874 13.2521 13.3137 13.2859 13.2324C13.3198 13.1512 13.3372 13.064 13.3372 12.976C13.3372 12.888 13.3198 12.8009 13.2859 12.7197C13.2521 12.6384 13.2025 12.5647 13.14 12.5027L8.93998 8.30937Z" fill="#686868" />
                </svg>
              </div>
              <div className="pu_flat_inner">
                <div className="pfi__left">
                  <ul className="pfi__info" style={{ marginBottom: "10px" }}>
                    <li>Общая площадь: {flat.total_area} м²</li>
                    <li>Жилая площадь: {flat.living_area} м²</li>
                    <li>Кухня: {flat.kitchen_area} м²</li>
                  </ul>

                  <Form
                    formClassName={formClassName}
                    puForm={true}
                    fields={fields}
                    hidden={hidden + ". Кол-во комнат: " + flat.rooms + " Общая площадь: " + flat.total_area + " Жилая площадь: " + flat.living_area}
                    btnTitle={btnTitle}
                    celtype={celtype}
                    template={template}
                    callback={() => successCallback()}
                    className={"pfi__form"}
                  />
                </div>
                <div className="pfi__right">
                  <img src={process.env.REACT_APP_PLANS_URL + flat.photo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    )
  }

  if (formClassName == "pu_ai_form") {
    return (
      <div style={{ display: "block" }} className="pu_rgba">
        <div className="pu_table">
          <div className="pu_cell">
            <div className="pu_akc_inner">
              <button title="Close (Esc)" type="button" className="mfp-close" onClick={() =>close()}>×</button>
              <div className="pu_ai_title">
                {title.split('<br/>')[0]}
                <span>{title.split('<br/>')[1]}</span>
              </div>
              
              <Form
                  formClassName={formClassName}
                  puForm={true}
                  fields={fields}
                  hidden={hidden}
                  btnTitle={btnTitle}
                  celtype={celtype}
                  template={template}
                  callback={() => successCallback()}
                  personal={true}
                  checkbox={true}
                />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="mfp-bg mfp-move-horizontal mfp-ready"></div>
      <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-move-horizontal mfp-ready" tabindex="-1" style={{ overflow: "hidden auto" }}>
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div id="callback-popup" className="popup">
              <div className="popup__header">
                <div className="popup__caption">{title}</div>
                <div className="popup__caption">{title2}</div>

                <div className="popup__description">
                  Отправьте заявку, мы ответим в течение 15 минут в рабочее время
                </div>
              </div>
              <div className="form-wrapper">
                <Form
                  formClassName={formClassName}
                  puForm={true}
                  fields={fields}
                  hidden={hidden}
                  btnTitle={btnTitle}
                  celtype={celtype}
                  template={template}
                  callback={() => successCallback()}
                  personal={true}
                  checkbox={true}
                />
                <div className="text-center my-2">
                  <a target="_blank" href="/docs/policy.pdf">политика конфиденциальности</a>
                </div>
              </div>
              <button title="Close (Esc)" type="button" className="mfp-close" onClick={(e) => { e.preventDefault(); close() }}>×</button>
            </div>
          </div>
          <div className="mfp-preloader">Loading...</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ModalC