import React, { useEffect, useState } from 'react'
import { useContext } from "react"
import { BlocksContext } from "../context/blocksContext"
import ModalC from './modal'
import { Form } from './form'
import { Swiper, SwiperSlide } from 'swiper/react';
import CountDown from './countdown'


export const Header = () => {
    const [isOpen, setModalState] = useState(null)
    const [isOpenSplitModal, setIsOpenSplitModal] = useState(null)
    const [isOpenCountDown, setIsOpenCountDown] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [openActionsForm, setActionsForm] = useState(false)
    const [buyForms, setBuyForms] = useState(false)
    const [buyFormsModal, setBuyFormsModal] = useState(-1)
    const [modalType, setModalType] = useState(-1)
    const [swiper, setSwiper] = useState()
    const blocks = useContext(BlocksContext)

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/action/action.json").then(res => res.json()).then((resp) => {
            setActionDate(resp.data)
        })
    }, [])

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")

        if (blocks.blocks < 14) {
            blocks.setBlocks(14)
            setTimeout(() => {

                window.scrollTo({
                    top: document.querySelector("#" + popup).offsetTop,
                    behavior: "smooth"
                })

            }, 1400)

        } else {
            window.scrollTo({
                top: document.querySelector("#" + popup).offsetTop,
                behavior: "smooth"
            })
        }

        setMobileOpen(false)
    }

    useEffect(()=>{
        if (swiper) {
        setTimeout(()=>{
            swiper.slideNext()
        }, 30000)
    }
    },[swiper])

    return (<React.Fragment>
        <div className="nav_mobile" style={{ display: mobileOpen ? "" : "none" }}>
            <button type="button" className="mfp-close" onClick={((e) => { e.preventDefault(); setMobileOpen(false) })}>×</button>
            <ul className="nav_mobile_list">
                <li><a href="location" onClick={clickMenu}>Расположение</a></li>
                <li><a href="about" onClick={clickMenu}>Дом</a></li>
                <li><a href="flat" onClick={clickMenu}>Квартиры</a></li>
                <li><a href="parking" onClick={clickMenu}>Парковка</a></li>
                {/* <li><a href="buy" onClick={clickMenu}>Способы покупки</a></li> */}
                <li><a href="developer" onClick={clickMenu}>Застройщик</a></li>
                <li><a href="contacts" onClick={clickMenu}>Контакты</a></li>
                <li><a href="commercy" onClick={clickMenu}>Коммерческие помещения</a></li>
            </ul>
        </div>

        <header className="header">
            <div className="container d-flex justify-content-between align-items-xl-start align-items-center">
                <div className="logo">
                    <img alt="" src="/img/logo_white.svg" className="lazy logo__img" />
                </div>
                <div className="head_center">
                    <div className='h_btn' onClick={(e) => { e.preventDefault(); setActionsForm(true) }}>Акции</div>
                    {/* <div className='h_btn' onClick={(e) => { e.preventDefault(); setBuyForms(true) }}>Способы покупки</div> */}
                </div>
                <div className="header__right d-flex flex-xl-column align-items-xl-end align-items-center">
                    <a style={{ zIndex: 10000 }} href="#" className="toggle-menu js-toggle-menu menu-open d-block d-xl-none" onClick={((e) => { e.preventDefault(); setMobileOpen(true) })}><span></span></a>
                    <div className="header__btn d-none d-xl-block">
                        <a href="#callback-popup" onClick={(e) => { e.preventDefault(); setModalState(true) }} className="js-popup btn btn--mini btn--red-to-blue">
                            <span className="btn__text">Заказать звонок</span>
                            <div className="btn__bg"></div>
                        </a>
                    </div>
                    <a href="tel:+74242451197" className="header__phone roistat-phone">8 (424) 245-11-97</a>
                </div>
            </div>
        </header>
        <section className='header_main_m'>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                onSwiper={(swiper) => setSwiper(swiper)}
                autoHeight={true}
            >
                <SwiperSlide>
                    <main className="main active">
                        <div className="container">
                            <div className="row">
                                <div className="offset-xl-3 col-12 col-xl-6 main__col-center">
                                    <h1 className="main__h1">Квартиры класса комфорт +</h1>
                                    <h2 className="main__h2">
                                        с панорамным видом на Амурский залив <br />
                                        г. Владивосток
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <img alt="" src="/img/header_bg-min.jpg" className="lazy main__bg" />
                        <a target="_blank" href="https://domrfbank.ru/" className="head_bankdom"><img alt="" src="/img/bankdom.png" /></a>
                    </main>
                </SwiperSlide>
                {/*<SwiperSlide>
                    <main className="main main_auto active">
                        <div className='header_slider_img'>
                            <img src='../img/header_slider_img1.jpg' />
                            <img src='../img/header_slider_img1_mobile.jpg' />
                        </div>
                        <div className='header_slide_btn' onClick={(e)=>{e.preventDefault(); setIsOpenSplitModal(true)}}></div>
                        <a target="_blank" href="https://domrfbank.ru/" className="head_bankdom"><img alt="" src="/img/bankdom.png" /></a>
                    </main>
                </SwiperSlide>*/}
            </Swiper>
            {/*<CountDown setIsOpenCountDown={setIsOpenCountDown} />*/}
        </section>
        
        {
            openActionsForm ? <main className="main active">
                {modalType == -1 ? <div className="pu_rgba">
                    <div className="pu_table">
                        <div className="pu_cell">
                            <div className="pu_akc">
                                <button title="Close (Esc)" type="button" className="mfp-close" onClick={(e) => { e.preventDefault(); setActionsForm(false) }}>×</button>
                                <div className="pu_akc__title">Акции и предложения</div>
                                <ul className='pu_akc__list' style={{display: "flex", justifyContent: "center"}}>
                                    { <li onClick={(e) => { e.preventDefault(); setModalType(0) }}>
                                        <div className="pu_al_title">
                                            Скидка на все квартиры до&nbsp;776 тыс. ₽
                                        </div>
                                        <img src='/img/pu_action_new_img3.jpg' />
                                        <div className="pu_al_title">
                                            Быстрый и выгодный обмен <br /> старого жилья на новое
                                        </div>
                                        <div className='pu_about'>Получить подробности</div>
                                    </li> }
                                    {<li onClick={(e) => { e.preventDefault(); setModalType(1) }}>
                                        <div className="pu_al_title">
                                        Удваиваем <br />
                                        материнский капитал!
                                        </div>
                                        <img src='/img/pu_action_new_img2.jpg' />
                                        <div className="pu_al_title">
                                            Предложение действует до <br /> 31.03.2025 г.
                                        </div>
                                        <div className='pu_about'>Получить подробности</div>
                                    </li>}
                                    <li onClick={(e) => { e.preventDefault(); setModalType(2) }}>
                                        <div className="pu_al_title">
                                            Рассрочка <br />
                                            от застройщика
                                        </div>
                                        <img src='/img/pu_action_new_img3.jpg' />
                                        <div className="pu_al_title">
                                            0% до 31.05.2025, <br />
                                            ПВ от 15%
                                        </div>
                                        <div className='pu_about'>Получить подробности</div>
                                    </li>
                                    <li onClick={(e) => { e.preventDefault(); setModalType(3) }}>
                                        <div className="pu_al_title">
                                            Скидки участникам СВО<br/> и их семья <br/>
                                            до 155 тыс. ₽
                                        </div>
                                        <img src='/img/pu_action_new_img4.jpg' />
                                        <div className="pu_al_title">
                                            Предложение действует до <br /> 31.03.2025 г.
                                        </div>
                                        <div className='pu_about'>Получить подробности</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> : <></>}
                {
                    {
                        0: <ModalC
                            fields={[
                                {
                                    name: "name",
                                    placeholder: "Ваше имя",
                                    required: false,
                                    type: "text",
                                    class: "in_name",
                                },
                                {
                                    name: "phone",
                                    placeholder: "Ваш телефон",
                                    required: true,
                                    type: "text",
                                    class: "in_phone",
                                },

                            ]}
                            position={window.scrollY}
                            hidden={`Скидка на все квартиры до 776 тыс. ₽`}
                            btnTitle={'Узнать подробнее'}
                            title={"Скидка на все квартиры до 776 тыс. ₽"}
                            celtype={"getSwap"}
                            template={2}
                            formClassName={"pu_ai_form"}
                            personal={true}
                            callback={() => { console.log("success") }}
                            close={() => { setModalType(-1) }}
                        />,
                        1: <ModalC
                            fields={[
                                {
                                    name: "name",
                                    placeholder: "Ваше имя",
                                    required: false,
                                    type: "text",
                                    class: "in_name",
                                },
                                {
                                    name: "phone",
                                    placeholder: "Ваш телефон",
                                    required: true,
                                    type: "text",
                                    class: "in_phone",
                                },

                            ]}
                            position={window.scrollY}
                            hidden={`Удваиваем материнский капитал!`}
                            btnTitle={'Узнать подробнее'}
                            title={"Удваиваем материнский капитал!"}
                            celtype={"getDouble"}
                            template={2}
                            formClassName={"pu_ai_form"}
                            personal={true}
                            callback={() => { console.log("success") }}
                            close={() => { setModalType(-1) }}
                        />,
                        2: <ModalC
                            fields={[
                                {
                                    name: "name",
                                    placeholder: "Ваше имя",
                                    required: false,
                                    type: "text",
                                    class: "in_name",
                                },
                                {
                                    name: "phone",
                                    placeholder: "Ваш телефон",
                                    required: true,
                                    type: "text",
                                    class: "in_phone",
                                },

                            ]}
                            position={window.scrollY}
                            hidden={`Рассрочка от застройщика`}
                            btnTitle={'Узнать подробнее'}
                            title={"Рассрочка от застройщика"}
                            celtype={"getInstalment"}
                            template={2}
                            formClassName={"pu_ai_form"}
                            personal={true}
                            callback={() => { console.log("success") }}
                            close={() => { setModalType(-1) }}
                        />,
                        3: <ModalC
                            fields={[
                                {
                                    name: "name",
                                    placeholder: "Ваше имя",
                                    required: false,
                                    type: "text",
                                    class: "in_name",
                                },
                                {
                                    name: "phone",
                                    placeholder: "Ваш телефон",
                                    required: true,
                                    type: "text",
                                    class: "in_phone",
                                },

                            ]}
                            position={window.scrollY}
                            hidden={`Скидки участникам СВО и их семья до 155 тыс. ₽`}
                            btnTitle={'Узнать подробнее'}
                            title={"Скидки участникам СВО и их семья <br/> до 155 тыс. ₽"}
                            celtype={"getSvo"}
                            template={2}
                            formClassName={"pu_ai_form"}
                            personal={true}
                            callback={() => { console.log("success") }}
                            close={() => { setModalType(-1) }}
                        />,


                    }[modalType]
                }
            </main> : <></>}


        {

            buyForms ? <div className="pu_rgba">
                <div className="pu_table">
                    <div className="pu_cell">
                        {buyFormsModal == -1 ? <div className="pu_ipoteka2">
                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={(e) => { e.preventDefault(); setBuyForms(false) }}>×</button>
                            <ul className="pu_ipoteka_list">
                                <li onClick={() => { setBuyFormsModal(0) }}>
                                    <div className="pil__inner">
                                        <div className="pil__title">
                                            Траншевая ипотека
                                        </div>
                                        <div className="pil__info">
                                            Квартиры от 7 000 руб/мес <br />
                                            ПСК 5,291% - 7,744%
                                        </div>
                                        <div className="pil__img">
                                            <img src="/img/pu_ipoteka_img1.png" />
                                        </div>
                                        <div className="pil__info2">
                                            Платеж до 31.10.24 составляет 7000 руб/мес, далее по 25 600 руб/мес. Ставка 5%. Первоначальный взнос от 20.1%. Сумма 6 млн руб. Срок 30 лет. Программа «Семейная ипотека» Предложение действительно до 31.05.2024. Ипотеку предоставляет ПАО Сбербанк. Лицензия №1481.
                                        </div>
                                        <div className="pil__btn">
                                            <span>Подробнее</span>
                                        </div>
                                    </div>
                                </li>
                                <li onClick={() => { setBuyFormsModal(1) }}>
                                    <div className="pil__inner">
                                        <div className="pil__title">
                                            Дальневосточная ипотека
                                        </div>
                                        <div className="pil__info">
                                            Квартиры от 25 000 руб/мес<br />
                                            ПСК 1,564% - 3,700%.
                                        </div>
                                        <div className="pil__img">
                                            <img src="/img/pu_ipoteka_img2.png" />
                                        </div>
                                        <div className="pil__info2">
                                            Ставка 1,3% на весь срок. Первоначальный взнос от 20.1%. Сумма 6 000 000 руб. Срок 20 лет. Предложение действительно до 31.05.2024. Ипотеку предоставляет АО «Банк ДОМ.РФ» Лицензия №2312.
                                        </div>
                                        <div className="pil__btn">
                                            <span>Подробнее</span>
                                        </div>
                                    </div>
                                </li>
                                <li onClick={() => { setBuyFormsModal(2) }}>
                                    <div className="pil__inner">
                                        <div className="pil__title">
                                            Льготная ипотека
                                        </div>
                                        <div className="pil__info">
                                            Ипотек для всех от 0,1%<br />
                                            ПСК 6,39% - 15,236%
                                        </div>
                                        <div className="pil__img">
                                            <img src="/img/pu_ipoteka_img3.png" />
                                        </div>
                                        <div className="pil__info2">
                                            Застройщик ООО СЗ "Каштановый двор" Программа "Господдержка 2020" от банка ПАО ВТБ. Лицензия №1000. Ставка 0.1% на 12 месяцев. Далее — базовая ставка 8% по программе. Срок кредита до 30 лет. Размер кредита до 6 млн руб. Минимальный размер первоначального взноса 30,1%. Информация действительна на 01.06.2024 г.
                                        </div>
                                        <div className="pil__btn">
                                            <span>Подробнее</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div> : <></>}
                        {
                            {
                                0: <div className='pu_akc_inner'>
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setBuyFormsModal(-1)}>×</button>
                                    <div className="pii__title">
                                        Траншевая ипотека
                                    </div>
                                    <div className="pii__content" style={{ marginTop: 0 }}>
                                        <div className="pii__content_l">
                                            <Form
                                                formClassName={"pu_ai_form"}
                                                puForm={true}
                                                fields={[
                                                    {
                                                        name: "name",
                                                        placeholder: "Ваше имя",
                                                        required: false,
                                                        type: "text",
                                                        class: "in_name",
                                                    },
                                                    {
                                                        name: "phone",
                                                        placeholder: "Ваш телефон",
                                                        required: true,
                                                        type: "text",
                                                        class: "in_phone",
                                                    },

                                                ]}
                                                hidden={"Траншевая ипотека"}
                                                btnTitle={"Узнать подробнее"}
                                                celtype={"getTranshIpot"}
                                                callback={() => setBuyFormsModal(3)}
                                                personal={true}
                                                checkbox={true}
                                            />
                                        </div>

                                    </div>
                                </div>,
                                1: <div className='pu_akc_inner'>
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setBuyFormsModal(-1)}>×</button>
                                    <div className="pii__title">
                                        Дальневосточная ипотека
                                    </div>
                                    <div className="pii__content" style={{ marginTop: 0 }}>
                                        <div className="pii__content_l">
                                            <Form
                                                formClassName={"pu_ai_form"}
                                                puForm={true}
                                                fields={[
                                                    {
                                                        name: "name",
                                                        placeholder: "Ваше имя",
                                                        required: false,
                                                        type: "text",
                                                        class: "in_name",
                                                    },
                                                    {
                                                        name: "phone",
                                                        placeholder: "Ваш телефон",
                                                        required: true,
                                                        type: "text",
                                                        class: "in_phone",
                                                    },

                                                ]}
                                                hidden={"Дальневосточная ипотека, 9 млн"}
                                                btnTitle={"Узнать подробнее"}
                                                celtype={"getDalnIpotV1"}
                                                callback={() => setBuyFormsModal(3)}
                                                personal={true}
                                                checkbox={true}
                                            />
                                        </div>
                                        <div className="pii__content_r">
                                            <img src="/img/pu_ipoteka_img2.png" />
                                        </div>
                                    </div>
                                </div>,
                                2: <div className='pu_akc_inner'>
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setBuyFormsModal(-1)}>×</button>
                                    <div className="pii__title">
                                        Льготная ипотека
                                    </div>
                                    <div className="pii__content" style={{ marginTop: 0 }}>
                                        <div className="pii__content_l">
                                            <Form
                                                formClassName={"pu_ai_form"}
                                                puForm={true}
                                                fields={[
                                                    {
                                                        name: "name",
                                                        placeholder: "Ваше имя",
                                                        required: false,
                                                        type: "text",
                                                        class: "in_name",
                                                    },
                                                    {
                                                        name: "phone",
                                                        placeholder: "Ваш телефон",
                                                        required: true,
                                                        type: "text",
                                                        class: "in_phone",
                                                    },

                                                ]}
                                                hidden={"Льготная ипотека"}
                                                btnTitle={"Узнать подробнее"}
                                                celtype={"getDalnIpotV2"}
                                                callback={() => setBuyFormsModal(3)}
                                                personal={true}
                                                checkbox={true}
                                            />
                                        </div>
                                        <div className="pii__content_r">
                                            <img src="/img/pu_ipoteka_img3.png" />
                                        </div>
                                    </div>
                                </div>,
                                3: <ModalC
                                    success={true}
                                    position={window.scrollY}
                                    close={() => { setBuyFormsModal(-1) }}
                                />

                            }[buyFormsModal]
                        }

                    </div>
                </div>
            </div> : <></>


        }


        {isOpen ? <ModalC
            title={"Закажите обратный звонок"}
            position={window.scrollY}
            puForm={true}
            fields={[
                {
                    name: "name",
                    placeholder: "Ваше имя",
                    required: false,
                    type: "text",
                    class: "in_style",
                },
                {
                    name: "phone",
                    placeholder: "Ваш телефон",
                    required: true,
                    type: "text",
                    class: "in_style",
                },
            ]}
            hidden={"Заказать звонок"}
            btnTitle={"Заказать звонок"}
            celtype={"getCalls"}
            template={2}
            callback={() => { console.log("success") }}
            personal={true}
            checkbox={true}
            close={() => { setModalState(false) }}
        /> : <div></div>}

        {isOpenCountDown? <ModalC
                title={"Квартира с господдержкой"}
                position={window.scrollY}
                puForm={true}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_style",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_style",
                    },
                ]}
                hidden={"Квартира с господдержкой"}
                btnTitle={"Узнать подробнее"}
                celtype={"getGosHelp"}
                template={2}
                callback={() => { console.log("success") }}
                //formClassName={"pu_ai_form"}
                personal={true}
                checkbox={true}
                close={() => { setIsOpenCountDown(false) }}
            />: <></>}

        {isOpenSplitModal ? <ModalC
            title={"Первый взнос частями"}
            position={window.scrollY}
            puForm={true}
            fields={[
                {
                    name: "name",
                    placeholder: "Ваше имя",
                    required: false,
                    type: "text",
                    class: "in_style",
                },
                {
                    name: "phone",
                    placeholder: "Ваш телефон",
                    required: true,
                    type: "text",
                    class: "in_style",
                },
            ]}
            hidden={"Первый взнос частями"}
            btnTitle={"Узнать подробнее"}
            celtype={"getFirstPaymentSplit"}
            template={2}
            callback={() => { console.log("success") }}
            personal={true}
            checkbox={true}
            close={() => { setIsOpenSplitModal(false) }}
        /> : <div></div>}
    </React.Fragment>
    )
}

export default Header