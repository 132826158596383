import React, { useState } from "react"
import { useSendForm } from "../hooks/send-from.hook"
import { PhoneInput } from "./phoneInput"
import SelectInput from "./selectInput"

export const Form = ({ fields, hidden, formTitle, formClassName, btnClassName, celtype, btnTitle, template, callback, puForm, checkbox }) => {
  const sendForm = useSendForm()
  const [checked, setChecked] = useState(true)
  if (formClassName == "pu_ai_form") {
    return <form className="pu_ai_form">
      <div className="pu_aif_title">
        Узнайте подробнее <br/>об акции
      </div>
      {fields.map((field) => {
        switch (field.type) {
          case "textarea":
            return <textarea className={" ym-record-keys name"} type={field.type} name={field.name}
                             placeholder={field.placeholder} req={field.required ? "y" : ""}></textarea>
          case "select":
            return <SelectInput field={field}/>
          case "text":
            if (field.name == "phone") {
              return <PhoneInput/>
            }
            return <div className="form__row">
              <div className="form__input-wrap">
                <input className={"form__input in_style ym-record-keys name"} type={field.type} name={field.name}
                       placeholder={field.placeholder} req={field.required ? "y" : ""}/>
              </div>
            </div>
        }
      })}
      <input type="hidden" className="text" value={hidden}/>
      <button className="pu_aif_btn" celtype={celtype} onClick={(e) => {
        sendForm.sendForm(e, callback);
      }}>{btnTitle}</button>

      <div className="text-center my-2">
        <a target="_blank" href="/docs/policy.pdf">политика конфиденциальности</a>
      </div>
    </form>
  }
  return (
      <form action="" method="post" className={formClassName ? formClassName : "ajax_form af_example form"}>
        {formTitle ? <div class="bcc__form_title">{formTitle}</div> : <></>}
      <fieldset className="form__fieldset">
        {fields.map((field) => {
          switch (field.type) {
            case "textarea":
              return <textarea className={" ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""}></textarea>
            case "select":
              return <SelectInput field={field} />
            case "text":
              if (field.name == "phone") {
                return <PhoneInput />
              }
              return <div className="form__row">
                <div className="form__input-wrap">
                  <input className={"form__input in_style ym-record-keys name"} type={field.type} name={field.name} placeholder={field.placeholder} req={field.required ? "y" : ""} />
                </div>
              </div>
          }
        })}
        <div className="form__row">
          <div className="form__btn-wrap">
            {btnClassName === "bcc__btn" ?
              <button className="bcc__btn" celtype={celtype} onClick={(e) => { sendForm.sendForm(e, callback); }}>{btnTitle}</button> :
              <button className={"btn form__btn btn--long btn--brown"} celtype={celtype} onClick={(e) => { sendForm.sendForm(e, callback); }}>
                <span className="btn__text">{btnTitle}</span>
                <div className="btn__bg"></div>
              </button>}
          </div>
        </div>
        <input type="hidden" className="text" value={hidden} />
      </fieldset>
    </form>

  )
}