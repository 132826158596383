import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';
import { useSendForm } from "../hooks/send-from.hook"
import { useState, Fragment } from 'react';
import { PhoneInput } from './phoneInput';
import ModalC from "./modal"

export const Contacts = () => {
    const [success, setSuccess] = useState(false)
    const sendForm = useSendForm()
    return (
        <Fragment>

            <section className="section section--gray section--pt0 _mPS2id-t mPS2id-target active mPS2id-target-first mPS2id-target-last" id="contacts">
                <div className="container">
                    <div className="caption">
                        <h2 className="caption__h2 caption__h2--red">
                            <span data-aos="fade-right" data-aos-offset="100" className="aos-init aos-animate">Контакты</span>
                        </h2>
                    </div>
                    <div className="tabs js-tabs-contacts">
                        <div className="tabs__blocks">
                            <div className="tabs__block active">
                                <div className="row contacts">
                                    <div className="col-md-4 contacts__col">
                                        <ul className="contacts__ul">
                                            <li className="contacts__li"><span>Центральный отдел продаж:</span><span>
                                                Владивосток, ул. Светланская, д.143, Помещение V
                                            </span></li>
                                            <li className="contacts__li"><span>Магазин квартир ЖК "Футурист"</span><span>
                                                ул. Карбышева, 11
                                            </span></li>
                                            <li className="contacts__li"><span>Телефон:</span><span className="roistat-phone">8 (423) 210-02-43</span></li>
                                            <li className="contacts__li"><span>E-mail:</span><span>info.vl@develug.ru</span></li>
                                            <li className="contacts__li"><span>Режим работы:</span><span>пн-пт: 09:00 - 20:00<br />сб: 10:00 - 17:00<br />вс: выходной</span></li>
                                        </ul>
                                        <ul className="cont_list">
                                            {/*<li><a target="_blank" href="https://www.develug.ru?utm_source=yand...mpaign=Fut"><img src="/img/soc/soc_list_ico1.png" /></a></li>
                                            <li><a target="_blank" href="https://www.youtube.com/channel/UCitLWKkLKW2lQAfxaZLZkuQ"><img src="/img/soc/soc_list_ico2.png" /></a></li>*/}
                                            <li><a target="_blank" href="https://t.me/develug_vl?utm_source=yan...mpaign=Fut"><img src="/img/soc/soc_list_ico3.png" /></a></li>
                                            <li><a target="_blank" href="https://vk.com/develug_vl?utm_source=y...mpaign=Fut"><img src="/img/soc/soc_list_ico4.png" /></a></li>
                                            {/*<li><a target="_blank" href="https://ok.ru/group/59599008628758?utm...mpaign=Fut"><img src="/img/soc/soc_list_ico5.png" /></a></li>*/}
                                        </ul>
                                        <div className="form-wrapper">
                                            <form action="" onSubmit={(e) => { e.preventDefault() }} method="post" className="ajax_form af_example form form--contacts">
                                                <div className="form__header">
                                                    <div className="form__caption">
                                                        Остались вопросы?
                                                    </div>
                                                    <div className="form__description">
                                                        Задайте их нашему специалисту в форме ниже:
                                                    </div>
                                                </div>
                                                <fieldset className="form__fieldset">
                                                    <div className="form__row form__row--2">
                                                        <div className="form__input-wrap">
                                                            <input type="text" name="name" placeholder="Имя" className="form__input" />
                                                        </div>
                                                        <PhoneInput noRow={true} />
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__textarea-wrap">
                                                            <textarea name="dop-info" data="вопрос" placeholder="Ваш вопрос" className="form__textarea dop-info"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form__row">
                                                        <div className="form__btn-wrap">
                                                            <button onClick={(e) => { sendForm.sendForm(e, () => setSuccess(true)); }} celtype="getQuestion" className="btn form__btn btn--long btn--red">
                                                                <span className="btn__text">Задать вопрос</span>
                                                                <div className="btn__bg"></div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <input type="hidden" className='text' value="Задать вопрос" />
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-8 contacts__col d-flex">
                                        <div className="contacts__map-container">
                                            <YMaps>
                                                <div id="map" style={{ width: "100%", height: "100%" }}>
                                                    <Map defaultState={{ center: [55.930360409770024, 37.627304041664146], zoom: 16, controls: [] }} style={{ width: "100%", height: "100%", position: "absolute" }}  >
                                                        <ZoomControl options={{ float: 'left' }} />
                                                        <Placemark geometry={[55.930360409770024, 37.627304041664146]}
                                                            options={{
                                                                iconLayout: 'default#image',
                                                                hideIconOnBalloonOpen: false,
                                                                iconImageSize: [54, 49],
                                                                iconImageOffset: [-27, -49],
                                                                cursor: 'default',
                                                                iconShadow: true,
                                                                balloonclose: true,
                                                                iconImageHref: 'img/map_ico.svg',
                                                                balloonPanelMaxMapArea: 0,
                                                            }}
                                                        />
                                                    </Map>
                                                </div>
                                            </YMaps>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {success ? <ModalC
                success={success}
                position={window.scrollY}
                close={() => { setSuccess(false) }}
            /> : <div></div>}
        </Fragment>
    )
}

export default Contacts